<template>
	<div class="col-12 col-lg-9">
        <section class="userSteps bg-white boxed max-1085">
            <div clas="row">
                <div class="col-12">
                    <h4 class="mb-4">{{(Object.keys(store.userDetail).length && store.userDetail.firstName)? store.userDetail.firstName : 'My'}} {{$t('my_feed.text1')}}</h4>
                </div>
            </div>
            <div class="vuewFullSocialFeed myPost row">
                <div class="col-12 col-md-12">
                    <Social :feedType="feedType"></Social>
                    <Nodata v-if="!(Object.keys(store.socialList).length && store.socialList.content && store.socialList.content.length > 0)"></Nodata>
                </div>
            </div>
        </section>
	</div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import Social from '@/views/trader/social/social.vue'
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data(){
            return {
                showLoginPopup: false,
                feedType: 2,
            }
        },
        components: {
            Social
        },
        methods: {
            feedList(){
                if(this.feedType=='2') this.store.callmyFeedPageList({},true)
            },
        },
        created() {
            this.feedList()
        },
   
    };
</script>